import router from "../../router";
import firebase from "firebase";
import axios from "axios";

const state = {
  user: {
    email: "",
    token:
      "eyJhbGciOiJSUzI1NiIsImtpZCI6IjRlOWRmNWE0ZjI4YWQwMjUwNjRkNjY1NTNiY2I5YjMzOTY4NWVmOTQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbmV4dG9yZGVyLWF1dGgiLCJhdWQiOiJuZXh0b3JkZXItYXV0aCIsImF1dGhfdGltZSI6MTYxOTc2NTY1MCwidXNlcl9pZCI6IkJIVEFyeE8wbW5hMXlHMVh6OG9KQ0ZIVkxDcjEiLCJzdWIiOiJCSFRBcnhPMG1uYTF5RzFYejhvSkNGSFZMQ3IxIiwiaWF0IjoxNjE5NzY1NjUwLCJleHAiOjE2MTk3NjkyNTAsImVtYWlsIjoiYWRtaW5AZ2V0b3JkZXJzLmFwcCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJhZG1pbkBnZXRvcmRlcnMuYXBwIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.B3PbLKWvg66_iQQiLmXMzGndTj0h6WtSNrfrBhBtjhJLJD7rgrBtZN-rabHdC3dOT15A-SeiykmOT89t-vKpj74U8k1SJIeZswVlU8QdU_otqnQAO_JzzluM2QIpP8T5uMvGTzqgQ4d-F9Adz0Wn-yBBN_E57qiAPexu0r5N9ln9H9viJqrezcMXgQppdBPuJoYLgipEnm7jeIVNMQYHOrHn__Sleu3qOoETAo0X2lo0HtFHL1RrSLeT4SHiwuqpECLIkCxmEcxKwbbvwRv3ZhN-lyBvyqk_KhAGY-dPRH-1I5lt7BkVZhJtAPASmNIH843J3RqtyPhqB3WeM3AtyA",
    uid: "",
  },
  loading: false,
};

const getters = {
  getLoading: (state) => state.loading,
  getAuthHeader: (state) => {
    const item = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": "9R03ClUKfaRHP6lkvg4349ZCfWd1XHP3rjQCk023",
      Authorization: state.user.token,
    };
    return item;
  },
};

const actions = {
  startLoad({ state }) {
    state.loading = true;
  },

  endLoad({ state }) {
    state.loading = false;
  },

  fetch({ state }, value) {
    // console.log("loading", state.loading);
    return new Promise((res) => {
      const item = {
        method: value.method,
        url: `https://api.getorders.app${value.url}`,
        data: { ...value.data },
        headers: {},
      };

      if (value.headers) {
        item.headers = { ...value.headers };
      } else {
        item.headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": "9R03ClUKfaRHP6lkvg4349ZCfWd1XHP3rjQCk023",
          Authorization: state.user.token,
        };
      }

      axios(item).then((d) => {
        if (d.data.status == "failed" && d.data.message == "unauthorize") {
          router.push("/");
        } else {
          res(d);
        }
      });
    });
  },

  userLogin({ state, dispatch }, value) {
    state.loading = true;
    return new Promise((res, rej) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(value.email, value.password)
        .then((d) => {
          // console.log("firebase", d);
          axios
            .post(
              "https://api.getorders.app/admin/auth",
              {
                email: d.user.email,
                fire_id: d.user.uid,
              },
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "x-api-key": "9R03ClUKfaRHP6lkvg4349ZCfWd1XHP3rjQCk023",
                  Authorization: d.user.za,
                },
              }
            )
            .then((data) => {
              if (data.data.status == "success") {
                state.user.email = d.user.email;
                state.user.uid = d.user.uid;
                state.user.token = d.user.za;
                dispatch("endLoad");
                res({ status: true });
              } else {
                dispatch("endLoad");
                res({ status: false, message: "invalid auth" });
              }
            })
            .catch((e) => {
              console.log(e);
              rej(e);
            });
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
