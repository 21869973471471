// import axios from "axios";

const state = {
  merchants_list: [],
  status: [
    "system",
    "submitted",
    "pending",
    "accepted",
    "rejected",
    "paid",
    "to deliver",
    "delivered",
  ],
};

const getters = {
  getMerchantList: (state) => state.merchants_list,
};

const actions = {
  fetchMerchants({ state, dispatch }, value) {
    return new Promise((res) => {
      dispatch(
        "fetch",
        { url: `/admin/merchant?page=${value.page}`, method: "get" },
        { root: true }
      ).then((d) => {
        state.merchants_list = d.data;
        dispatch("endLoad", null, { root: true });
        res(d);
      });
    });
  },

  async searchMerchant({ dispatch }, value) {
    return new Promise((res) => {
      dispatch("fetch", {
        url: `/admin/merchant/search`,
        method: "post",
        data: {
          search: value,
        },
      }).then((response) => {
        // console.log(response);
        state.merchants_list = response.data;
        dispatch("endLoad", null, { root: true });
        res(response);
      });
    });
  },

  fetchAccount({ dispatch }) {
    return new Promise((res) => {
      dispatch("fetch", {
        url: `/accounts`,
        method: "get",
      }).then((response) => {
        // console.log("r", response);
        // state.merchants_list = response.data;
        dispatch("endLoad", null, { root: true });
        res(response);
      });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
