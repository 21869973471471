// import axios from "axios";
import router from "../../router";

const state = {
  merchant: {},
  products: [],
  vouchers: [],
  orders: [],
  inventory: [],
  vendor: [],
  selectedOutlet: null,
};

const getters = {
  getImpersonate: (state) => state.merchant,
  getProducts: (state) => state.products,
  getVouchers: (state) => state.vouchers,
  getOrders: (state) => state.orders,
  getInventory: (state) => state.inventory,
  getVendor: (state) => state.vendor,
  getSelectedOutlet: (state) => state.selectedOutlet,
};

const actions = {
  impersonate({ state }, value) {
    return new Promise((res) => {
      state.merchant = value;
      if (value.outlets) {
        state.selectedOutlet = value.outlets[0].id;
      }
      router.push("/impersonate/account/profile");
      res({ status: true });
    });
  },

  fetchProducts({ state, dispatch }) {
    return new Promise((res) => {
      let url = state.selectedOutlet
        ? `/products?impersonate=${state.merchant.fire_id}&outlet_id=${state.selectedOutlet}`
        : `/products?impersonate=${state.merchant.fire_id}`;
      dispatch(
        "fetch",
        {
          url,
          method: "get",
        },
        { root: true }
      ).then((d) => {
        state.products = d.data;
        dispatch("endLoad", null, { root: true });
        res(true);
      });
    });
  },

  fetchVouchers({ state, dispatch }) {
    return new Promise((res) => {
      dispatch(
        "fetch",
        {
          url: `/vouchers?impersonate=${state.merchant.fire_id}`,
          method: "get",
        },
        { root: true }
      ).then((d) => {
        state.vouchers = d.data;
        dispatch("endLoad", null, { root: true });
        res(true);
      });
    });
  },

  fetchOrders({ state, dispatch }) {
    return new Promise((res) => {
      dispatch(
        "fetch",
        {
          url: `/orders?impersonate=${state.merchant.fire_id}`,
          method: "get",
        },
        { root: true }
      ).then((d) => {
        // console.log("orders", d);
        state.orders = d.data;
        dispatch("endLoad", null, { root: true });
        res(true);
      });
    });
  },

  fetchInventory({ state, dispatch }) {
    return new Promise((res) => {
      dispatch(
        "fetch",
        {
          url: `/inventory?impersonate=${state.merchant.fire_id}`,
          method: "get",
        },
        { root: true }
      ).then((d) => {
        // console.log("orders", d);
        state.inventory = d.data;
        dispatch("endLoad", null, { root: true });
        res(true);
      });
    });
  },

  fetchVendor({ state, dispatch }) {
    return new Promise((res) => {
      dispatch(
        "fetch",
        {
          url: `/vendor?impersonate=${state.merchant.fire_id}`,
          method: "get",
        },
        { root: true }
      ).then((d) => {
        // console.log("orders", d);
        state.vendor = d.data;
        dispatch("endLoad", null, { root: true });
        res(true);
      });
    });
  },

  switchOutlet({ state }, value) {
    state.selectedOutlet = value;
    // console.log("state", state.selectedOutlet);
  },

  saveMerchant({ state, dispatch }) {
    return new Promise((res) => {
      dispatch(
        "fetch",
        {
          url: `/settings?impersonate=${state.merchant.fire_id}`,
          method: "post",
          data: state.merchant,
        },
        { root: true }
      ).then(() => {
        // console.log("save setting", d);
        // state.orders = d.data;
        dispatch("endLoad", null, { root: true });
        res(true);
      });
    });
  },

  filterOrder({ state, dispatch }, value) {
    return new Promise((res) => {
      dispatch(
        "fetch",
        {
          url: `/orders?impersonate=${state.merchant.fire_id}&from=${value.from}&to=${value.to}`,
          method: "get",
        },
        { root: true }
      ).then((d) => {
        // console.log("orders", d);
        state.orders = d.data;
        dispatch("endLoad", null, { root: true });
        res(true);
      });
    });
  },

  updateSubs({ state, dispatch }, value) {
    // console.log("merchant", state.merchant);
    // console.log("value", value);
    return new Promise((res) => {
      dispatch(
        "fetch",
        {
          url: `/admin/accounts/expired-date`,
          method: "post",
          data: { ...value, fire_id: state.merchant.fire_id },
        },
        { root: true }
      ).then((d) => {
        // console.log("test", d);
        state.merchant.expired_at = d.data.expired_at;
        dispatch("endLoad", null, { root: true });
        // state.orders = d.data;
        // dispatch("endLoad", null, { root: true });
        res(d);
      });
    });
  },

  generateToken({ dispatch }, value) {
    return new Promise((res) => {
      dispatch(
        "fetch",
        {
          url: `/admin/impersonate-as`,
          method: "post",
          data: { fire_id: value },
        },
        { root: true }
      ).then((d) => {
        console.log("token", d);
        res(d);
      });
    });
  },
};

const mutations = {
  updateMerchantSettings(state, value) {
    state.merchant = value;
    // console.log("updated data", state.merchant);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
