import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import "./assets/global.scss";
import firebase from "firebase/app";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faQuidditch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import constant from "./constant";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
library.add(faQuidditch);

firebase.initializeApp(constant.config);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  created() {
    this.$router.push({ name: "Home" });
  },
  render: (h) => h(App),
}).$mount("#app");
