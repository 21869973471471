const state = {
  route: [],
};

const getters = {
  allRoutes: (state) => state.route,
};

const actions = {};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
