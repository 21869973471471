<template>
  <v-app>
    <v-main :class="dark ? 'dark' : 'backdrop'">
      <div>
        <!-- <div class="wave-3">
          <div class="wave-1">
            <div class="wave-2"> -->
        <router-view />
        <div v-if="loading !== false">
          <div class="flex justify-end fab">
            <v-progress-circular
              :size="50"
              color="purple"
              indeterminate
              class="white outlined rounded"
            ></v-progress-circular>
          </div>
        </div>
      </div>
      <!-- </div>
        </div>
      </div> -->
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",

  data: () => ({
    //
  }),

  computed: {
    ...mapGetters({
      loading: "getLoading",
      dark: "getDark",
    }),
  },
  watch: {
    $route: "updateRoute",
  },
  methods: {
    updateRoute() {
      this.route = this.$route.name;
      this.$store.dispatch("endLoad");
    },
    beforeMount() {
      // console.log(this.$route);
    },
  },
};
</script>
<style lang="scss">
.load-box {
  display: flex;

  .box {
    border: 1px solid grey;
    border-radius: 4%;
    background-color: white;
    padding: 2%;
    width: 50px;
  }
}
.dark {
  background-color: #708090;
}
.fab {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 60px;
  right: 10px;
  z-index: 20;
}
.backdrop {
  background-color: #afeeee;
}

.wave-2 {
  display: block;
  position: relative;
  top: -2px;
  height: 155px;
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 1440 155" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="rgba(175, 238, 238, 1)" d="M 0 0 C 273.6 0 68.39999999999998 73 342 73 L 342 73 L 342 0 L 0 0 Z" stroke-width="0"></path> <path fill="rgba(175, 238, 238, 1)" d="M 341 73 C 951.4 73 493.6 132 1104 132 L 1104 132 L 1104 0 L 341 0 Z" stroke-width="0"></path> <path fill="rgba(175, 238, 238, 1)" d="M 1103 132 C 1372.6 132 1170.4 155 1440 155 L 1440 155 L 1440 0 L 1103 0 Z" stroke-width="0"></path></svg>');
  background-size: cover;
  background-repeat: no-repeat;
}
.wave-3 {
  display: block;
  position: relative;
  top: -2px;
  height: 500px;
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 1430 500" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="rgba(250, 250, 210, 1)" d="M 0 0 C 211.20000000000002 0 52.79999999999998 250 264 250 L 264 250 L 264 0 L 0 0 Z" stroke-width="0"></path> <path fill="rgba(250, 250, 210, 1)" d="M 263 250 C 775.8000000000001 250 391.19999999999993 425 904 425 L 904 425 L 904 0 L 263 0 Z" stroke-width="0"></path> <path fill="rgba(250, 250, 210, 1)" d="M 903 425 C 1324.6 425 1008.4 500 1430 500 L 1430 500 L 1430 0 L 903 0 Z" stroke-width="0"></path></svg>');
  background-size: cover;
  background-repeat: no-repeat;
}

.wave-1 {
  display: block;
  position: relative;
  top: -2px;
  height: 302px;
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 1440 302" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="rgba(230, 230, 250, 1)" d="M 0 0 C 277.9 0 119.10000000000002 139 397 139 L 397 139 L 397 0 L 0 0 Z" stroke-width="0"></path> <path fill="rgba(230, 230, 250, 1)" d="M 396 139 C 741.0999999999999 139 543.9000000000001 261 889 261 L 889 261 L 889 0 L 396 0 Z" stroke-width="0"></path> <path fill="rgba(230, 230, 250, 1)" d="M 888 261 C 1274.4 261 1053.6 302 1440 302 L 1440 302 L 1440 0 L 888 0 Z" stroke-width="0"></path></svg>');
  background-size: cover;
  background-repeat: no-repeat;
}
.loader {
  color: #778899;
  font-size: 5px;
  margin: 20px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

@media (max-width: 1439px) {
  .container {
    background-size: contain;
  }
  .wave-1 {
    background-size: contain;
  }
  .wave-2 {
    background-size: contain;
  }
  .wave-3 {
    background-size: contain;
  }
}
</style>
