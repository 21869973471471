const state = {
  user_data: [
    {
      name: "Frozen Yogurt",
      icon: 1,
      expire_by: "2019-09-12 15:47:56",
      contact_no: "012-3214040",
      system: "GetOrders",
      role: "Super Admin",
      status: "Ongoing",
    },
  ],
  stats: null,
  statsV2: null,
  global_settings: [],
  dash_settings: {
    dark: false,
  },
  invoice: null,
};

const getters = {
  getUserData: (state) => state.user_data,
  getDark: (state) => state.dash_settings.dark,
  getStats: (state) => state.stats,
  getStatsV2: (state) => state.statsV2,
  getGlobalSettings: (state) => state.global_settings,
};

const actions = {
  switchDark({ state }) {
    state.dash_settings.dark = !state.dash_settings.dark;
  },

  fetchAccounts({ state, dispatch }) {
    // console.log(state);
    dispatch(
      "fetch",
      { url: "/admin/accounts", method: "get" },
      { root: true }
    ).then((d) => {
      // console.log(d);
      state.user_data = d.data.admins;
      dispatch("endLoad", null, { root: true });
    });
  },

  async fetchInvoice({ dispatch }, value) {
    // console.log(state);
    // console.log(value);
    return new Promise((res) => {
      dispatch("fetch", {
        url: "/guest/invoice",
        method: "get",
        headers: {
          order_id: value,
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": "EF95vGTnuo1GDqSQw6BmN41IR1G5R0Li8E5WIxlm",
        },
      }).then((response) => {
        res(response);
      });
    });
  },

  async fetchStats({ dispatch }) {
    return new Promise((res) => {
      dispatch("fetch", {
        url: `/admin/dashboard/stats`,
        method: "get",
      }).then((response) => {
        // console.log(response);
        state.stats = response.data;
        dispatch("endLoad", null, { root: true });
        res(response);
      });
    });
  },

  async fetchStatsV2({ dispatch }) {
    return new Promise((res) => {
      dispatch("fetch", {
        url: `/admin/dashboard/stats/v2`,
        method: "get",
      }).then((response) => {
        // console.log(response);
        state.statsV2 = response.data;
        dispatch("endLoad", null, { root: true });
        res(response);
      });
    });
  },

  async fetchGlobal({ dispatch }) {
    return new Promise((res) => {
      dispatch("fetch", {
        url: `/admin/global-settings`,
        method: "get",
      }).then((response) => {
        state.global_settings = response.data;
        dispatch("endLoad", null, { root: true });
        res(response);
      });
    });
  },

  saveGlobalSettings({ state, dispatch }) {
    return new Promise((res) => {
      dispatch(
        "fetch",
        {
          url: `/admin/global-settings`,
          method: "post",
          data: state.global_settings,
        },
        { root: true }
      ).then((r) => {
        state.global_settings = r.data;
        dispatch("endLoad", null, { root: true });
        res(true);
      });
    });
  },
};

const mutations = {
  updateGlobalSettings(state, value) {
    state.global_settings = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
