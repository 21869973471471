import vuex from "vuex";
import vue from "vue";
import routes from "./module/route-stores";
import user from "./module/user-stores";
import dashboard from "./module/dashboard-stores";
import merchant from "./module/merchants.stores";
import impersonate from "./module/impersonate-stores";
vue.use(vuex);

export default new vuex.Store({
  modules: {
    routes,
    user,
    dashboard,
    merchant,
    impersonate,
  },
});
