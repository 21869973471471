import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  historyApiFallback: true,
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("../components/Login/index.vue"),
    },
    {
      path: "/dashboard/:child?/:param?",
      name: "Dashboard",
      component: () => import("../components/Dashboard/index.vue"),
    },
    {
      path: "/impersonate/:child?/:param?",
      name: "Dashboard",
      component: () => import("../components/Impersonate/index.vue"),
    },
    //redirect
    {
      path: "/dashboard",
      redirect: "/dashboard/main",
    },
  ],
});
